import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { Box, Center } from "@chakra-ui/react";
// import Header from "./Header/header";
// import Footer from "./Footer/Footer";
import Header from './About/headerAB';
import Footer from './About/footerAB';
import useWindowDimensions from "../useWindow.tsx";

const CardKnightWebGL = () => {
  const { height, width } = useWindowDimensions();
  const final_height = 1.1 * height;
  const final_width = 0.8 * width;

  return (
    <>
      <Header />
      <Center

        bgImage="repeating-radial-gradient(  #0c0a0a 80%,#2f312f 90%,#3f4549 90%)"
        bgRepeat="repeat"
        bgSize={"65px 65px"}
      >
            <iframe
              src="./CardKnightBuild-Runtime/index.html"
              width={final_width}
              height={final_height}
            ></iframe>
      </Center>
      <Footer />
    </>
  );
}

export default CardKnightWebGL;